import { Box, Button, Header, Select, Stack, TextInput, Title } from "@mantine/core"
import { useForm } from '@mantine/form';

interface SettingsValues {
  businessName: string,
  companySize: number,
  businessEmail: string,
  phoneNumber: string,
}

export const Settings = () => {

  // Fetch API call to get current settings
  const loadInitialValues = () => {
    // do nothing for now. 
  }

  const form = useForm<SettingsValues>({
    initialValues: { businessName: '', companySize: 0, businessEmail: '', phoneNumber: '' },
    validate: {
      businessEmail: (value: string) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
    }
  });

  // Have the page as a form to edit the settings
  return (
    <>
      <Title>Account Settings</Title>
      <Box maw={320} my={10} >
        <form onSubmit={form.onSubmit(console.log)}>
          <Stack spacing={20}>
            <TextInput label="Business Email" placeholder="Business Email" {...form.getInputProps('businessEmail')} />
            <TextInput label="Business Name" placeholder="Business Name" {...form.getInputProps('businessName')} />
            <TextInput label="Phone Number" placeholder="Phone Number" {...form.getInputProps('phoneNumber')} />
            <Select
              label="Company Size"
              placeholder="Pick one"
              data={[
                { value: '100', label: '0-100' },
                { value: '500', label: '100-500' },
                { value: '1500', label: '500-1500' },
                { value: '5000', label: '1500+' },
              ]}
            />
              <Button type="submit" w={200}>Submit</Button>
          </Stack>
        </form>
      </Box>
    </>
  );
}