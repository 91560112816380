import React, { Dispatch, FC, ReactNode, SetStateAction, createContext, useState } from "react";


export const AuthContext = createContext<{
  authedUser: any | undefined,
  setAuthedUser: Dispatch<SetStateAction<any>>
}>({
  authedUser: undefined,
  setAuthedUser: (authedUser) => { }
})

type Props = {
  children?: React.ReactNode
}
export const AuthProvider: FC<Props> = ({ children }) => {
  const [authedUser, setAuthedUser] = useState<any>();

  return (
    <AuthContext.Provider value={{ authedUser, setAuthedUser }}>{children}</AuthContext.Provider>
  )
}