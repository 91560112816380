import { Navbar, Button, Box, Stack } from "@mantine/core"
import { Auth } from "aws-amplify"
import React, { FC, useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { AuthContext } from "../context/auth-context"

type Props = {
  children?: React.ReactNode
}

export const Navigation: FC<Props> = ({ children }) => {
  const { authedUser, setAuthedUser } = useContext(AuthContext)

  async function getCurrentUser() {
    try {
      const authUser = await Auth.currentAuthenticatedUser()
      setAuthedUser(authUser)
    } catch (e) {
      // If there is no authed user, Auth.currentAuthenticatedUser() just throws an error
      // because reasons.
      setAuthedUser(undefined)
    }
  }

  useEffect(() => {
    getCurrentUser()
  }, [])

  useEffect(() => {
  }, [authedUser])

  const navigate = useNavigate()
  return (
    <>
      <Navbar width={{ sm: 200 }} p="md">
        {authedUser && (<Navbar.Section >
          <Stack justify="center">
            <Button onClick={() => navigate("/")} w={150}>Home</Button>
            <Button onClick={() => navigate("/about")} w={150}>About</Button>
            <Button onClick={() => navigate("/blog")} w={150}>Blog</Button>
            <Button onClick={() => navigate("/calendar")} w={150}>Calendar</Button>
            <Button onClick={() => navigate("/contacts")} w={150}>Contacts</Button>
            <Button onClick={() => navigate("/pricing")} w={150}>Pricing</Button>
            <Button onClick={() => navigate("/settings")} w={150}>Settings</Button>
            <Button onClick={() => navigate("/venues")} w={150}>Venues</Button>
          </Stack>
        </Navbar.Section>)}
        <Navbar.Section grow mt={200}>
          {!authedUser && <Button onClick={() => {
            Auth.federatedSignIn()
          }
          } w={150} >Login</Button>}
          {authedUser && <Button c={"gray"} onClick={() => {
            Auth.signOut()
          }
          } w={150} >Logout</Button>}
        </Navbar.Section>
      </Navbar>
      {children}
    </>
  )
}