import { Amplify } from '@aws-amplify/core'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { ThemeProvider } from './ThemeProvider'
import { AuthProvider } from './context/auth-context'
import { Navigation } from './components/navigation'
import { Homepage } from './pages/homepage'
import { AppShell, Header, Title } from '@mantine/core'
import { AuthWrapper } from './components/auth-wrapper'
import { About } from './pages/about'
import { Blog } from './pages/blog'
import { Calendar } from './pages/calendar'
import { Contacts } from './pages/contacts'
import { Pricing } from './pages/pricing'
import { Settings } from './pages/settings'
import { Venues } from './pages/venues'

Amplify.configure({
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: process.env.REACT_APP_COGNITO_REGION,

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: false,

    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    authenticationFlowType: 'USER_PASSWORD_AUTH',

    // OPTIONAL - Hosted UI configuration
    oauth: {
      domain: `${process.env.REACT_APP_COGNITO_DOMAIN}.auth.us-east-1.amazoncognito.com`,
      scope: ['openid'],
      redirectSignIn: 'http://localhost:3000',
      redirectSignOut: 'http://localhost:3000',
      responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
    }
  }
})

export default function App() {
  return (
    <BrowserRouter>
      <ThemeProvider>
        <AuthProvider>
          <AppShell padding="md"
            navbar={<Navigation></Navigation>}
            header={<Header height={60} p="sm"><Title>Expo App</Title></Header>}>
            <Routes>
              <Route path='/'>
                <Route index element={<Homepage />} />
                <Route path='venues' element={<AuthWrapper><Venues /></AuthWrapper>} />
                <Route path='settings' element={<AuthWrapper><Settings /></AuthWrapper>} />
                <Route path='pricing' element={<AuthWrapper><Pricing /></AuthWrapper>} />
                <Route path='contacts' element={<AuthWrapper><Contacts /></AuthWrapper>} />
                <Route path='calendar' element={<AuthWrapper><Calendar /></AuthWrapper>} />
                <Route path='blog' element={<AuthWrapper><Blog /></AuthWrapper>} />
                <Route path='about' element={<AuthWrapper><About /></AuthWrapper>} />
              </Route>
            </Routes>
          </AppShell>
        </AuthProvider>
      </ThemeProvider>
    </BrowserRouter>
  )
}
