import React, { FC, useContext, useEffect } from "react";
import { AuthContext } from "../context/auth-context";
import { useNavigate } from "react-router-dom";

type IProps = {
  children?: React.ReactElement;
}

export const AuthWrapper: FC<IProps> = ({ children }) => {
  const { authedUser } = useContext(AuthContext);
  const navigate = useNavigate()

  useEffect(() => {
    if (!authedUser) {
      navigate('/')
    }
  });

  return <>{children}</>
}